









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface ISolubilityIodateInput {
  cthiosulfate: number;
  ksp: number;
}

export default defineComponent({
  name: 'LabTitration',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    concThiosulfate: {
      type: Number as PropType<ISolubilityIodateInput['cthiosulfate']>,
      default: 0.05,
    },
    ksp: {
      type: Number as PropType<ISolubilityIodateInput['ksp']>,
      default: 250e-8,
    },
  },
  computed: {
    labData(): ISolubilityIodateInput {
      return {
        cthiosulfate: this.concThiosulfate,
        ksp: this.ksp,
      };
    },
    stringLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
