<template>
  <lab-task> <lab-solubility-iodate :conc-thiosulfate="concThiosulfate" :ksp="ksp" /></lab-task>
</template>

<script>
import LabSolubilityIodate from '../simulations/LabSolubilityIodate.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'SolubilityIodateSIM',
  components: {LabSolubilityIodate, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    concThiosulfate() {
      return this.taskState.getValueBySymbol('concThiosulfate').content.toFloat();
    },
    ksp() {
      return this.taskState.getValueBySymbol('ksp').content.toFloat();
    },
  },
});
</script>
